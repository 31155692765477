import axios from 'axios';
import { useMainStore } from '../store/index';

const api = axios.create({ baseURL: process.env.VUE_APP_DS_API_URL });
const adminApi = axios.create({ baseURL: process.env.VUE_APP_DS_ADMIN_API_URL });
const emailApi = axios.create({ baseURL: process.env.VUE_APP_DS_EMAIL_API_URL });
const customersAPI = axios.create({ baseURL: process.env.VUE_APP_DS_API_CUSTOMERS_URL });

const getFromStore = (prop) => {
  const store = useMainStore();
  return store[prop];
};

[api, adminApi, emailApi, customersAPI].forEach(axiosApi => {
  axiosApi.interceptors.request.use((config) => {
    const store = useMainStore();
    config.headers.Authorization = store.token;
    return config;
  }, (error) => Promise.reject(error));
});

const addConfiguration = async (marketId, offerId, configuration) => adminApi.post(`/v1/markets/${marketId}/offers/${offerId}/configuration`, configuration);
const uploadStaticImage = async (staticImageData) => adminApi.post('/v1/upload-static-image', staticImageData);
const addRawConfiguration = async (marketId, offerId, rawConfiguration) => adminApi.post(`/v1/markets/${marketId}/offers/${offerId}/raw-configuration`, { rawConfiguration });
const getRawConfiguration = async (marketId=getFromStore('market'), offerId) => adminApi.get(`/v1/markets/${marketId}/offers/${offerId}/raw-configuration`);
const addTranslations = async (translations) => adminApi.post('/v1/translations/add', translations);
const searchFmatStaff = async (query) => adminApi.get('/v1/staff/search', { params: { query: query }});
const getOfferIds = async (marketId=getFromStore('market')) => api.get(`/v1/markets/${marketId}/user/offers/ids`);
const getOffersMetadata = async () => adminApi.get('/v1/offers/metadata');
const addOfferAccess = async (offerId, users) => adminApi.post(`/v1/offers/${offerId}/access`, users);
const removeOfferAccess = async (offerId, user) => adminApi.delete(`/v1/offers/${offerId}/access`, { params: { externalStaffReference: user.externalStaffReference,  federationUserAccountId: user.federationUserAccountId }});
const getOfferAccessList = async () => adminApi.get('/v1/offers/accesslist');
const getFmatStaffsByReferences = async (staffReferences) => adminApi.post(`/v1/staff/getlist`, staffReferences);
const getFmatStaffByFederationId = async (federationUserAccountId) => adminApi.get(`v1/staff/${federationUserAccountId}/federated`);
const getFmatCustomersByReferences = async (customerReferences) => adminApi.post(`/v1/customers/getlist`, customerReferences);
const addVehicleSpecification = async (marketId, offerId, specification) => adminApi.post(`/v1/markets/${marketId}/offers/${offerId}/specification`, specification);
const resetFinalizedState = async (offerId) => adminApi.post(`/v1/offers/${offerId}/finalize/reset`);
const getOffersByFinalizedState = async (finalized, marketId=getFromStore('market')) => adminApi.get(`/v2/offers`, { params: { finalized: finalized, market: marketId }});
const getFgwUsersByRole = async (role) => adminApi.get(`/v1/fgw/roles/${role}/users`);
const getTranslations = async (locale, marketId=getFromStore('market')) => api.get(`/v1/markets/${marketId}/translations/${locale}`);
const addFgwUsersRoles = async (fgwIdsAndRoles) => adminApi.post(`v1/fgw/users/roles`, fgwIdsAndRoles);
const getImages = async (specification) => adminApi.post(`/v1/images`, specification);
const addImageServiceSpecification = async (marketId, offerId, specification, queryParams = {}) => adminApi.post(`/v1/markets/${marketId}/offers/${offerId}/is-specification`, specification, { params: queryParams });
const getFgwClientRoles = async () => adminApi.get(`v1/fgw/client/roles`);
const getFgwUserRoles = async (federationUserAccountId) => adminApi.get(`v1/fgw/users/${federationUserAccountId}/roles`);
const removeFgwUserRole = async (federationUserAccountId, role) => adminApi.delete(`v1/fgw/user/${federationUserAccountId}/roles/${role}`);
const getOfferConfigurationV2 = async (offerId, rawConfiguration = false) => api.get(`/v2/offers/${offerId}/configuration`, { params: { rawConfiguration }});
const addUserMetadata = async (userMetadataList) => adminApi.post(`/v1/users/metadata`, userMetadataList);
const updateUserMetadata = async (userMetadata) => adminApi.put(`/v1/users/metadata`, userMetadata);
const getUserMetadata = async (federationUserAccountId) => api.get(`/v1/users/${federationUserAccountId}/metadata`);
const getOfferAccessByFederationId = async (federationUserAccountId, marketId=getFromStore('market')) => adminApi.get(`/v1/markets/${marketId}/users/${federationUserAccountId}/access`);
const addUserResetPasswordMapping = async (federationUserAccountId, userInfo) => adminApi.post(`/v1/users/${federationUserAccountId}/resetpasswordmapping`, userInfo);
const getUserResetPasswordMapping = async (federationUserAccountId) => api.get(`/v1/users/${federationUserAccountId}/resetpasswordmapping`);
const removeUserResetPasswordMapping = async (federationUserAccountId) => api.delete(`/v1/users/${federationUserAccountId}/resetpasswordmapping`);
const getOfferEventTracking = async (offerId) => adminApi.get(`/v1/offers/${offerId}/tracking-events`);
const validateConfiguration = async (specification) => adminApi.post(`/v1/offers/validate`, specification);
const getMarketLanguages = async (marketId) => adminApi.get(`/v1/markets/${marketId}/languages`);
const getVariantCodeDescriptions = async (variantCodesPayload) => adminApi.post(`/v1/variant-code/description`, variantCodesPayload);
const getOfferMisc = async (offerId) => adminApi.get(`/v1/offers/${offerId}/misc`);
const initSECMConfiguration = async (configurationPayload) => adminApi.post(`/v1/configurations`, configurationPayload);
const getSECMConfiguration = async (configurationId, queryParams = {}) => adminApi.get(`/v1/configurations/${configurationId}`, { params: queryParams });
const getSECMPriceList = async (marketId, vehicleTypeId) => adminApi.get(`/v1/markets/${marketId}/vehicletypes/${vehicleTypeId}/price-list-header`);
const getMarketViewHeaders = async (marketId) => adminApi.get(`/v1/markets/${marketId}/view-headers`);
const addBatch = async (payload) => adminApi.post(`/v1/batches`, payload);
const listBatches = async (marketId) => adminApi.get(`/v1/batches?market=${marketId}`);
const getBatch = async (batchId) => adminApi.get(`/v1/batches/${batchId}`);
const addBatchOffers = async (payload) => adminApi.post(`/v1/batches/offers`, payload);
const listBatchOffers = async (batchId = 'ALL') => adminApi.get(`/v1/batches/${batchId}/offers`);
const getBatchOffer = async (offerId) => adminApi.get(`/v1/batches/offers/${offerId}`);
const addBatchRawOfferConfigurations = async (payload) => adminApi.post(`/v1/batches/raw-configuration`, payload);
const addBatchOfferConfigurations = async (payload) => adminApi.post(`/v1/batches/configuration`, payload);
const addBatchVehicleSpecifications = async (payload) => adminApi.post(`/v1/batches/specification`, payload);
const addBatchImageServiceSpecifications = async (payload) => adminApi.post(`/v1/batches/is-specification`, payload);
const sendOfferEmailToCustomer = async (offerEmailData) => emailApi.post(`/v1/offers/send-email`, offerEmailData);
const getEmailTemplates = async (tags = '', marketId = getFromStore('market') ) => emailApi.get(`/v1/offers/email-templates?tags=${tags}&markets=${marketId}`);
const addVehicleModelMetadata = async (marketId, modelId, metadata) => adminApi.post(`/v1/vehicle-models/markets/${marketId}/models/${modelId}/metadata`, metadata);
const addVehicleModelConfiguration = async (marketId, modelId, configuration) => adminApi.post(`/v1/vehicle-models/markets/${marketId}/models/${modelId}/configuration`, { configuration });
const addVehicleModelSpecification = async (marketId, modelId, specification) => adminApi.post(`/v1/vehicle-models/markets/${marketId}/models/${modelId}/specification`, { specification });
const addVehicleModelSpecificationTranslation = async (marketId, modelId, translations) => adminApi.post(`/v1/vehicle-models/markets/${marketId}/models/${modelId}/specification-translations`, { translations });
const getallVehicleModels = async (marketId = getFromStore('market')) => adminApi.get(`/v1/vehicle-models/markets/${marketId}/models-metadata`);
const getVehicleModelMetadata = async (marketId, modelId) => adminApi.get(`/v1/vehicle-models/markets/${marketId}/models/${modelId}/metadata`);
const searchFgwUsers = async (query) => adminApi.get('/v1/fgw/users/search', { params: { query: query }});
const getUserInfoByFederationId = async (federationUserAccountId) => adminApi.get(`/v1/fgw/users/${federationUserAccountId}/info`);
const getAllSalesRepMetadata = async (marketId) => adminApi.get(`/v1/vehicle-models/markets/${marketId}/sales-reps`);
const addSalesRepMetadata = async (marketId, salesRepId, salesRepMetadata) => adminApi.post(`/v1/vehicle-models/markets/${marketId}/sales-rep/${salesRepId}/metadata`, salesRepMetadata);
const getSalesRepMetadata = async (marketId, salesRepId) => adminApi.get(`/v1/vehicle-models/markets/${marketId}/sales-rep/${salesRepId}/metadata`);
const getLinkMetadata = async (marketId, linkId) => adminApi.get(`/v1/vehicle-models/markets/${marketId}/links/${linkId}/metadata`);
const addMasterOffer = async (marketId, payload) => adminApi.post(`/v1/markets/${marketId}/offers/master`, payload);
const listMasterOffers = async (marketId) => adminApi.get(`/v1/markets/${marketId}/offers/master`);
const getMasterOffer = async (marketId, masterOfferId, rawConfiguration = false) => adminApi.get(`/v1/markets/${marketId}/offers/master/${masterOfferId}`, { params: { rawConfiguration }});
const updateMasterOffer = async (marketId, masterOfferId, payload) => adminApi.put(`/v1/markets/${marketId}/offers/master/${masterOfferId}`, payload);
const deleteMasterOffer = async (marketId, masterOfferId) => adminApi.delete(`/v1/markets/${marketId}/offers/master/${masterOfferId}`);
const getOfferings = async (type, status, searchTerm, startCreatedDate, endCreatedDate, lastKey, marketId=getFromStore('market')) => adminApi.get(`/v1/markets/${marketId}/offerings?type=${type}&status=${status}&searchTerm=${searchTerm}&startCreatedDate=${startCreatedDate}&endCreatedDate=${endCreatedDate}&lastKey=${lastKey}`);
const getOfferComments = async (offerId, federationUserAccountId, marketId = getFromStore('market')) => api.get(`/v1/offers/comments`, { params: { offerId, federationUserAccountId, market: marketId } });
const addCampaignEmails = async (payload, marketId=getFromStore('market')) => adminApi.post(`/v1/markets/${marketId}/campaign`, payload);
const getCampaignEmails = async (startCreatedDate, endCreatedDate, email, lastKey, marketId=getFromStore('market')) => adminApi.get(`/v1/markets/${marketId}/campaign-emails?startCreatedDate=${startCreatedDate}&endCreatedDate=${endCreatedDate}&email=${email}&lastKey=${lastKey}`);
const getOffersData = async (startCreatedDate, endCreatedDate, lastKey, status, searchTerm, marketId=getFromStore('market')) => adminApi.get(`/v1/markets/${marketId}/getOffersData?startCreatedDate=${startCreatedDate}&endCreatedDate=${endCreatedDate}&status=${status}&searchTerm=${searchTerm}&lastKey=${lastKey}`);
const searchDSFgwUsers = async (query) => adminApi.get('/v1/fgw/ds/users/search', { params: { query: query }});
const addFeatureToggle = async (marketId = getFromStore('market'), payload) => adminApi.post(`/v1/markets/${marketId}/feature-toggle`, payload);
const listFeatureToggles = async (marketId = getFromStore('market')) => adminApi.get(`/v1/markets/${marketId}/feature-toggle`);
const getFeatureToggle = async (marketId = getFromStore('market'), featureKey) => adminApi.get(`/v1/markets/${marketId}/feature-toggle/${encodeURIComponent(featureKey)}`);
const updateFeatureToggle = async (marketId = getFromStore('market'), featureKey, payload) => adminApi.put(`/v1/markets/${marketId}/feature-toggle/${encodeURIComponent(featureKey)}`, payload);
const deleteFeatureToggle = async (marketId = getFromStore('market'), featureKey) => adminApi.delete(`/v1/markets/${marketId}/feature-toggle/${encodeURIComponent(featureKey)}`);
const getContactRequests = async (startCreatedDate, endCreatedDate, lastKey, marketId=getFromStore('market')) => adminApi.get(`/v1/market/${marketId}/contact-requests?startCreatedDate=${startCreatedDate}&endCreatedDate=${endCreatedDate}&lastKey=${lastKey}`);
const addMarketTranslations = async (marketISO, translations) => adminApi.post(`/v1/markets/${marketISO}/translation`, translations);
const addFpcCodesTranslations = async (locale, fpcCodesTranslations) => adminApi.post(`/v1/fpcCodes/translations/${locale}`, fpcCodesTranslations);
const getAppConfiguration = async (marketId = getFromStore('market'), configKey) => api.get(`/v1/markets/${marketId}/app-configuration/${encodeURIComponent(configKey)}`);
const getOfferStatus = async (offerId = getFromStore('offerId')) => api.get(`/v1/offers/${offerId}/status`);
const getMagicLinksMetadata = async (startCreatedDate, endCreatedDate, email, lastKey, marketId=getFromStore('market')) => customersAPI.get(`/v1/markets/${marketId}/magic-links?startCreatedDate=${startCreatedDate}&endCreatedDate=${endCreatedDate}&email=${email}&lastKey=${lastKey}`);
const updateLinkMetadata = async (linkId, linkMetadata, marketId=getFromStore('market')) => customersAPI.put(`/v1/markets/${marketId}/magic-links/${linkId}`, linkMetadata);

export {
  addConfiguration,
  uploadStaticImage,
  addRawConfiguration,
  getRawConfiguration,
  addTranslations,
  searchFmatStaff,
  getOfferIds,
  getOffersMetadata,
  addOfferAccess,
  removeOfferAccess,
  getOfferAccessList,
  getFmatStaffsByReferences,
  getFmatStaffByFederationId,
  getFmatCustomersByReferences,
  addVehicleSpecification,
  resetFinalizedState,
  getOffersByFinalizedState,
  getFgwUsersByRole,
  getTranslations,
  addFgwUsersRoles,
  getImages,
  addImageServiceSpecification,
  getFgwClientRoles,
  getFgwUserRoles,
  removeFgwUserRole,
  getOfferConfigurationV2,
  addUserMetadata,
  updateUserMetadata,
  getUserMetadata,
  getOfferAccessByFederationId,
  addUserResetPasswordMapping,
  getUserResetPasswordMapping,
  removeUserResetPasswordMapping,
  getOfferEventTracking,
  validateConfiguration,
  getMarketLanguages,
  getVariantCodeDescriptions,
  getOfferMisc,
  initSECMConfiguration,
  getSECMConfiguration,
  getSECMPriceList,
  getMarketViewHeaders,
  addBatch,
  listBatches,
  getBatch,
  addBatchOffers,
  listBatchOffers,
  getBatchOffer,
  addBatchRawOfferConfigurations,
  addBatchOfferConfigurations,
  addBatchVehicleSpecifications,
  addBatchImageServiceSpecifications,
  sendOfferEmailToCustomer,
  getEmailTemplates,
  addVehicleModelMetadata,
  addVehicleModelConfiguration,
  addVehicleModelSpecification,
  addVehicleModelSpecificationTranslation,
  getallVehicleModels,
  getVehicleModelMetadata,
  searchFgwUsers,
  getUserInfoByFederationId,
  getAllSalesRepMetadata,
  addSalesRepMetadata,
  getSalesRepMetadata,
  getLinkMetadata,
  addMasterOffer,
  listMasterOffers,
  getMasterOffer,
  updateMasterOffer,
  deleteMasterOffer,
  getOfferComments,
  getOfferings,
  addCampaignEmails,
  getCampaignEmails,
  getOffersData,
  searchDSFgwUsers,
  addFeatureToggle,
  listFeatureToggles,
  getFeatureToggle,
  updateFeatureToggle,
  deleteFeatureToggle,
  getContactRequests,
  addMarketTranslations,
  addFpcCodesTranslations,
  getAppConfiguration,
  getOfferStatus,
  getMagicLinksMetadata,
  updateLinkMetadata,
};
