import { defineStore } from 'pinia';
import { getTranslations } from '../services/digitalSalesService';
import { v4 as uuidv4 } from 'uuid';

export const useMainStore = defineStore('main', {
  state: () => ({
    token: null,
    locale: 'en-GB',
    fallbackLocale: 'en-GB',
    translations: {},
    languages: [
      { locale: 'en-GB', description: 'English' },
      { locale: 'pl-PL', description: 'Polish' },
      { locale: 'ko-KR', description: 'Korean' },
      { locale: 'de-DE', description: 'German' },
    ],
    toasts: [],
    env: process.env.VUE_APP_ENV,
    envs: [
      { env: 'dev', description: 'Dev', url: process.env.VUE_APP_DS_ADMIN_URL_DEV },
      { env: 'prod', description: 'Prod', url: process.env.VUE_APP_DS_ADMIN_URL_PROD },
    ],
    userOverview: {
      searchText: '',
      accessFrom: '',
      accessTo: '',
    },
    offerOverview: {
      sorting: {
        sortKey: 'offerId',
        sortSubKey: null,
        sortOrderAsc: true,
      },
      filters: {
        filterText: '',
        createdDateStart: '',
        createdDateEnd: '',
        validUntilStart: '',
        validUntilEnd: '',
      },
    },
    markets: [
      { marketId: 'pl', description: 'Poland', emailTemplates: ['pullflow-campaign', 'end-year-sales-campaign', 'end-year-sales-campaign-short'] },
      { marketId: 'kr', description: 'Korea' },
      { marketId: 'de', description: 'Germany', emailTemplates: ['magic-link'] },
    ],
    market:'pl',
    selectedVehicleModel: {},
    selectedSalesRep: { language: {} },
    offerComments: {
      selectedOfferId: null,
      selectedFederationUserAccountId: null,
    },
    masterOfferTags: [
      { tag: 'public', description: 'Public offer to show without login' },
    ],
    basePriceMinValue: 89999,
  }),
  getters: {
    activeMarket(state) {
      // Return the active market configuration
      return state.markets.find(market => market.marketId === state.market) ?? null;
    },
    getEmailTemplates () {
      return this.markets.find(market => market.marketId === this.market)?.emailTemplates ?? null;
    },
  },
  actions: {
    getTranslation(key) {
      return this.translations[this.locale]?.[key] ||
        this.translations[this.fallbackLocale]?.[key] ||
        key;
    },
    loadTranslations() {
      if (this.translations[this.locale]) return;
      getTranslations(this.locale)
        .then(response => {
          this.translations[this.locale] = response.data;
        });
    },
    addToast(toast) {
      toast.id = uuidv4();
      this.toasts.push(toast);
    },
    addSuccessToast(toast) {
      if (typeof toast === 'string') {
        toast = {
          header: toast,
        };
      }
      this.addToast({
        ...toast,
        type: 'success',
      });
    },
    addErrorToast(toast) {
      if (typeof toast === 'string') {
        toast = {
          header: toast,
        };
      }
      this.addToast({
        ...toast,
        type: 'error',
      });
    },
    clearToast(id) {
      this.toasts = this.toasts.filter(x => x.id !== id);
    },
    resetCurrentVehicleModel() {
      this.selectedVehicleModel = {};
    },
    resetCurrentSalesRep() {
      this.selectedSalesRep = { language: {} };
    },
  },
});

